const env = document.querySelector('meta[name="env"]') ?
    document.querySelector('meta[name="env"]').content :
    'production';

const CONFIG = {
    local: {
        clientId: '0oafudk8vcCsZRpRj0h7',
        shim: 'https://qa-ids-shim.mlb.com',
        okta: 'https://qa-ids.mlb.com',
        issuer: 'https://qa-ids.mlb.com/oauth2/ausg0y0s07iKGqVKv0h7',
        redirectUri: 'http://local.mlb.com:3000',
        entitlementServices: 'https://mlbentitlementservices.commerce-qa.mlbinfra.com/entitlements?',
        profile: 'https://profile-qa.mlb.com/api/v1'
    },
    development: {
        clientId: '0oafudk8vcCsZRpRj0h7',
        shim: 'https://qa-ids-shim.mlb.com',
        okta: 'https://qa-ids.mlb.com',
        issuer: 'https://qa-ids.mlb.com/oauth2/ausg0y0s07iKGqVKv0h7',
        redirectUri: 'https://dev-gcp.mlb.com/login',
        entitlementServices: 'https://mlbentitlementservices.commerce-qa.mlbinfra.com/entitlements?',
        profile: 'https://profile-qa.mlb.com/api/v1'
    },
    qa: {
        clientId: '0oafudk8vcCsZRpRj0h7',
        shim: 'https://qa-ids-shim.mlb.com',
        okta: 'https://qa-ids.mlb.com',
        issuer: 'https://qa-ids.mlb.com/oauth2/ausg0y0s07iKGqVKv0h7',
        redirectUri: 'https://qa-aws.mlb.com/login',
        entitlementServices: 'https://mlbentitlementservices.commerce-qa.mlbinfra.com/entitlements?',
        profile: 'https://profile-qa.mlb.com/api/v1'
    },
    beta: {
        clientId: '0oap7wa857jcvPlZ5355',
        shim: 'https://ids-shim.mlb.com',
        okta: 'https://ids.mlb.com',
        issuer: 'https://ids.mlb.com/oauth2/aus1m088yK07noBfh356',
        redirectUri: 'https://beta-aws.mlb.com/login',
        entitlementServices: 'https://mlbentitlementservices.mlb.com/entitlements?',
        profile: 'https://profile.mlb.com/api/v1'
    },
    production: {
        clientId: '0oap7wa857jcvPlZ5355',
        shim: 'https://ids-shim.mlb.com',
        okta: 'https://ids.mlb.com',
        issuer: 'https://ids.mlb.com/oauth2/aus1m088yK07noBfh356',
        redirectUri: 'https://www.mlb.com/login',
        entitlementServices: 'https://mlbentitlementservices.mlb.com/entitlements?',
        profile: 'https://profile.mlb.com/api/v1'
    }
};

module.exports = {
    config: CONFIG[env] || CONFIG.production,
    env,
    CONFIG
};
