// index.js <=> mlb-okta.js

import 'whatwg-fetch';
import 'es6-promise/auto';
import Cookie from 'js-cookie';
import { OktaAuth, generateState } from '@okta/okta-auth-js';
import { config } from './config';
import {
    _clearCookiesAndStorage,
    _validateTokenExpiration,
    _authConfig,
    _throwRedirectError,
    _expatToTime,
    _refreshTokens,
    _validateFreshTokens,
    _setTokens
} from './lib';
import { ACCESS_TOKEN, ID_TOKEN } from './constants';

const splitDomain = window.location.hostname.split('.');
const domain = `.${splitDomain.slice(-2).join('.')}`;


// Note: Do not re-assign
let authClient = new OktaAuth(_authConfig(config)); // eslint-disable-line

/**
 * @description: Validates the current idToken in localStorage against the current JWK
 * from Okta, checks kid, and ensures claims have not been added or removed.
 * @returns: {Promise()}
 * @example: mlbOkta.isLoggedIn().then(claims => console.log(claims)).catch(f => f);
 */
export const isLoggedIn = () => {
    return new Promise((resolve) => {
        const accessToken = authClient.tokenManager.getSync(ACCESS_TOKEN);
        const idToken = authClient.tokenManager.getSync(ID_TOKEN);
        const tokenHasExpired = !_validateTokenExpiration(accessToken) || !_validateTokenExpiration(idToken);
        const oktaId = Cookie.get('oktaid');
        // don't make any calls to okta if no oktaid cookie is present
        if (!oktaId && domain === '.mlb.com') {
            return _throwRedirectError(false, '', true);
        } else if (tokenHasExpired) {
            if (
                document.readyState === 'complete' ||
                document.readyState === 'loaded' ||
                document.readyState === 'interactive'
            ) {
                return resolve(
                    _refreshTokens(authClient)
                        .then(() => {
                            _validateFreshTokens(authClient);
                            return {
                                loggedIn: true,
                                accessToken: authClient.tokenManager.getSync(ACCESS_TOKEN),
                                idToken: authClient.tokenManager.getSync(ID_TOKEN)
                            };
                        })
                        .catch((error) => {
                            _throwRedirectError(false, error, true);
                        })
                );
            } else {
                return new Promise(() => {
                    window.addEventListener(
                        'DOMContentLoaded',
                        () => {
                            resolve(
                                _refreshTokens(authClient)
                                    .then(() => {
                                        _validateFreshTokens(authClient);
                                        return {
                                            loggedIn: true,
                                            accessToken: authClient.tokenManager.getSync(ACCESS_TOKEN),
                                            idToken: authClient.tokenManager.getSync(ID_TOKEN)
                                        };
                                    })
                                    .catch((error) => {
                                        _throwRedirectError(false, error, true);
                                    })
                            );
                        },
                        false
                    );
                });
            }
        } else {
            return resolve({
                loggedIn: true,
                accessToken,
                idToken
            });
        }
    }).catch((error) => {
        _clearCookiesAndStorage(authClient, domain);
        // authClient.signOut();
        return Promise.reject(error);
    });
};

/**
 * @description: Expire Cookies, Delete LocalStorage
 * @returns: promise
 */
export const logoutOnly = () => {
    _clearCookiesAndStorage(authClient, domain);
    return authClient.signOut().catch((error) => console.warn(error));
};

/**
 * @description: Expire Cookies, Delete LocalStorage, refresh page
 * @returns: promise
 */
export const logout = () => {
    return logoutOnly().then(() => window.location.reload());
};

/**
 * @description: Wrapper for getting tokens.
 * @returns: {object}
 * @example: const { accessToken } = mlbOkta.getToken(ATK);
 */
export const getToken = (type = ID_TOKEN) => {

    const replacements = {
        access_token: ACCESS_TOKEN,
        id_token: ID_TOKEN
    };

    try {
        const normalizedType = type.replace(/access_token|id_token/gi, (matched) => replacements[matched]);
        const token = authClient.tokenManager.getSync(normalizedType);

        if (token) {
            return token;
        } else {
            console.warn(`Client has no ${normalizedType}, please check type or login.`);
            return {};
        }
    } catch (err) {
        console.warn('Warning: could not get geToken', err);
        return {};
    }

};

/**
 * @description: get the ID Token JWT payload
 * @returns: {object}
 * @example: const subject = decodeIdToken().sub;
 */

export const decodeIdToken = () => {

    try {
        const { idToken } = getToken(ID_TOKEN);

        if (idToken) {
            return authClient.token.decode(idToken).payload;
        }
        return {};

    } catch (err) {
        console.warn('Warning: unable to decode token', err);
        return {};
    }

};

/**
 * @description: Simple redirection
 * @returns: void
 */
export const redirectToLogin = (url = '/login', redirectUri = window.location.pathname) => {
    const hasQuery = url.indexOf('?') > -1;
    const sep = hasQuery ? '&' : '?';
    window.location.assign(`${url}${sep}redirectUri=${redirectUri}`);
};

/**
 * @description: Returns access to the auth client instance
 * @returns: {Object()}
 * @example: const authClient = mlbOkta.getAuthClient();
 * @deprecated Okta's internal authClient should not be used directly, {@link getToken} should be used to retrieve tokens
 */
export const getAuthClient = () => {
    authClient.tokenManager.get = getToken;
    return authClient;
};

/**
 * @description: Returns access to the auth config
 * @returns: {Object()}
 * @example: const config = mlbOkta.getAuthConfig();
 */
export const getAuthConfig = () => config;

/**
 * @description: Create a mai (my account info) Cookie from Id Token claims
 * @returns: {string} cookie name
 */
export const setMaiCookie = () => {

    const cookieName = 'mai';
    const maiProps = [
        'lastName',
        'firstName',
        'birthYear',
        'birthDay',
        'birthMonth',
        'avatar',
        'email'
    ];

    const cookieOptions = {
        expires: 365,
        path: '/',
        domain
    };

    const claims = decodeIdToken();

    const maiValue = Object.keys(claims)
        .filter((claim) => maiProps.indexOf(claim) !== -1)
        .filter((claim) => (claims[claim]))
        .map((maiProp) => {

            const key = (maiProp === 'email') ? '_email' : maiProp;
            const val = claims[maiProp];

            return `${key}=${val}`;
        })
        .join('&');

    Cookie.set(cookieName, maiValue, cookieOptions);

    return cookieName;
};

/**
 * @description: Create an oktaid cookie with the current Okta ID from the ID Token payload
 * @returns: {string} cookie name
 */
export const setOktaIdCookie = () => {

    const inOneYear = new Date(new Date().getTime() + (365 * 24 * 60 * 60 * 1000));
    const cookieName = 'oktaid';
    const oktaId = decodeIdToken().sub || '';

    const cookieOptions = {
        expires: inOneYear,
        path: '/',
        domain
    };
    Cookie.set(cookieName, oktaId, cookieOptions);
    return cookieName;

};

/**
 * @description: Create a Session Cookie with the current Id Token in the Token Manager
 * @returns: {string} cookie name
 */
export const setIdTokenCookie = () => {

    const inOneMinute = new Date(new Date().getTime() + (60 * 1000));
    const cookieName = 'okta-id-token';
    const idToken = getToken(ID_TOKEN);

    const idTokenClaims = idToken.claims || {};

    const reducedClaims = ({
        aud,
        sub,
        email,
        firstName,
        lastName,
        birthYear,
        birthMonth,
        birthDay
    }) => ({
        aud,
        sub,
        email,
        firstName,
        lastName,
        birthYear,
        birthMonth,
        birthDay
    });

    const reducedToken = {
        claims: reducedClaims(idTokenClaims)
    };

    const cookieOptions = {
        expires: inOneMinute,
        path: '/',
        secure: true,
        domain
    };
    Cookie.set(cookieName, JSON.stringify(reducedToken), cookieOptions);
    return cookieName;
};

/**
 * @description: Create a Session Cookie with the current Access Token in the Token Manager
 * Use opts.useExpires: true to make cookie expire with same time as token
 * @param: {object} opts { useExpires: boolean }
 * @returns: {string} cookie name
 */
export const setAccessTokeninCookie = (opts = {}) => {

    const { useExpires } = opts;
    const cookieName = 'okta-access-token';
    const accessToken = getToken(ACCESS_TOKEN);
    const expiresAt = new Date(_expatToTime(accessToken.expiresAt));
    const inOneMinute = new Date(new Date().getTime() + (60 * 1000));
    const expires = useExpires ? expiresAt : inOneMinute;

    const cookieOptions = {
        expires,
        path: '/',
        secure: true,
        domain
    };
    Cookie.set(cookieName, JSON.stringify(accessToken), cookieOptions);
    return { name: cookieName, expires }; // you can't read expires, so return it
};

/**
 * @description: Create new instance of OktaAuth SDK using custom config
 * @returns: void
 * @param: {object} customConfig
 * @example: mlbOkta.updateConfig({ ...custom config values... });
 */
export const updateConfig = (customConfig) => {
    try {
        Object.assign(config, customConfig);
        authClient = new OktaAuth(_authConfig(config));

    } catch (error) {
        console.warn('Error updating config for OktaAuth: ', error);
    }
};

/**
 * @description: Check whether client is entitled for this path
 * @returns: promise / fetch
 * @param: {array} pageEntitlements
 * @example: mlbOkta.isEntitled({ ...entitlements...});
 */
export const isEntitled = (pageEntitlements = {
    entitlements: []
}) => {

    // lets check for the token first.
    try {
        const token = getToken(ACCESS_TOKEN).accessToken;
        const {
            entitlements
        } = pageEntitlements;
        const uid = authClient.token.decode(token).payload.uid;
        const entitlementUrl = `${config.entitlementServices}principalType=user&principalId=${uid}`;

        return fetch(entitlementUrl, {
                headers: { // eslint-disable-line
                    Authorization: `Bearer ${token}` // eslint-disable-line
                } // eslint-disable-line
            }) // eslint-disable-line
            .then((response) => response.json())
            .then((response) => {
                // validate entitlements here
                const entitlementsContents = response.contents;
                const hasEntitlements = entitlementsContents.filter((e) => {
                    return entitlements.indexOf(e.entitlementCode) !== -1;
                });

                if (hasEntitlements.length < 1) {
                    console.warn('entitlements issue. user has:', entitlementsContents, 'but needs:', entitlements);
                    throw new Error('does not have valid entitlements');
                }

                return true;

            })
            .catch((err) => {
                console.warn('entitlements request error', err.message);
                return false;
            });

    } catch (warning) {
        console.warn(warning);
        return Promise.reject(false);
    }
};

export const setTokens = (tokens) => {
    _setTokens(authClient, tokens);
};

export const handleIdle = () => {
    authClient.tokenManager.on('expired', () => {
        _refreshTokens(authClient);
    });

    authClient.tokenManager.on('error', (error) => {
        console.warn('TokenManager error: ', error);
    });
};

export const generateStateToken = () => {
    return generateState();
};
