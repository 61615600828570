//src/lib.js

import Cookie from 'js-cookie';
import { ACCESS_TOKEN, ID_TOKEN, OKTA_TOKEN_STORAGE } from './constants';


// export for consistent cookie references
export const _loggedInCookies = ['mai', 'mlbunifieddata', 'okta-access-token'];

/**
 * @description: makes sure auth config is consistent
 * @returns: object
 * @private
 */
export const _authConfig = (opts = {}, overrides = {}) => {
    const options = Object.assign({}, opts, overrides);

    return {
        clientId: options.clientId,
        url: options.okta,
        issuer: options.issuer,
        redirectUri: `${window.location.origin}/login`,
        tokenManager: options.tokenManager || { autoRenew: false },
        pkce: true
    };
};

/**
 * @description: Removes auth cookies and local storage information
 * @returns: void
 * @private
 */
export function _clearCookiesAndStorage(authClient, domain) {
    try {
        _loggedInCookies.forEach((loggedInCookie) => {
            Cookie.remove(loggedInCookie, {
                path: '/',
                domain
            });
        });
        authClient.tokenManager.clear();
    } catch (err) {
        console.warn('Warning: removing auth cookies or storage.', err);
    }
}

/**
 * @description: helper to make sure token dates are rfc with cookie dates
 * @returns: void
 */
export function _expatToTime(expiresAt) {
    return parseInt(`${expiresAt}000`, 10);
}

/**
 * @description: Validates token using token existence and expiration time.
 * @returns: boolean
 * @private
 */
export function _validateTokenExpiration(token) {
    if (token) {
        const expiresAt = new Date(_expatToTime(token.expiresAt)).getTime();
        const now = Date.now();
        return expiresAt > now;
    }

    return false;
}

/**
 * Throws a special error with redirect flag
 * @param {function} assertion
 * @param {string} errorMessage
 * @param {boolean} redirect
 */
export const _throwRedirectError = (assertion = (f) => f, errorMessage = '', redirect = true) => {
    // note, assertion should throw with message and redirect flag
    try {
        assertion.call(null);
    } catch (_err) {
        _err.message = errorMessage;
        _err.redirect = redirect;
        throw _err;
    }
};

/**
 * Validate a truthy value or throw.
 * @param {any} value
 */
export const _truthy = (value) => () => {
    if (!value) {
        throw new Error();
    }
};

/**
 * @description sets accessToken and idToken in token manager
 * @returns: void
 * @private
 */
export function _setTokens(authClient, tokens) {
    // remove old tokens with underscores in key, as they cause js errors
    authClient.tokenManager.clear();

    authClient.tokenManager.add(ID_TOKEN, tokens.idToken);
    authClient.tokenManager.add(ACCESS_TOKEN, tokens.accessToken);

    // persist old keys for apps directly accessing local storage
    const updatedTokens = JSON.parse(localStorage.getItem(OKTA_TOKEN_STORAGE));
    updatedTokens.id_token = updatedTokens[ID_TOKEN];
    updatedTokens.access_token = updatedTokens[ACCESS_TOKEN];
    localStorage.setItem(OKTA_TOKEN_STORAGE, JSON.stringify(updatedTokens));
}

/**
 * @description: Refreshes expired tokens in token manager
 * @returns: void
 * @private
 */
export function _refreshTokens(authClient) {

    return authClient.token
        .getWithoutPrompt()
        .then((response) => {
            _setTokens(authClient, response.tokens);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

export function _validateFreshTokens(authClient) {

    const freshIdToken = authClient.tokenManager.getSync(ID_TOKEN);
    const freshAccessToken = authClient.tokenManager.getSync(ACCESS_TOKEN);
    _throwRedirectError(_truthy(freshIdToken), 'Id token is not set.', true);
    _throwRedirectError(_truthy(freshAccessToken), 'Access token is not set.', true);
}
